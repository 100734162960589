import React from "react"
import { StaticQuery, graphql } from "gatsby"
import {FaMapMarkedAlt} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"





const RoadmapPage = () => (

  

  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
        strapiRoadmap {
          Planned {
            Title
            Priority
            Description
            id
          }
          Launched {
            Title
            Priority
            Description
            id
          }
          InProgress {
            Title
            Priority
            Description
            id
          }
          InConsideration {
            Title
            Description
            Priority
            id
          }
        }
      }
    `}

   

    render={data => (
      
    <Layout type="roadmap">
        <SEO title="Roadmap" />


        <section className="roadmap-section">
        
                <div className="roadmap-menu-container">
                  <ul className="uk-tab uk-flex-left roadmap-menu" data-uk-tab="swiping:false; connect: .roadmap-container">
                      <li className="uk-disabled"><div className="roadmap-menu-title">Roadmap <FaMapMarkedAlt/></div></li>
                      <li><a href="#0">Planned</a></li>
                      <li><a href="#0">In progress</a></li>
                      <li><a href="#0">Launched</a></li>
                      <li><a href="#0">In consideration</a></li>
                  </ul>
                </div>
    
                <ul className="uk-switcher roadmap-container">
                    <li></li>
                    <li className="roadmap-category">
                      <div>
                        <div className="uk-grid-medium uk-child-width-1-3@m uk-grid-match" data-uk-grid> 
                          {data.strapiRoadmap.Planned.map((item, i) => {
                            return (
                              <div key={item.id}>
                                <div className={item.Priority + "-priority roadmap-card uk-card uk-card-default uk-card-body"}>
                                  <div className="card-title">{item.Title}</div>
                                    <div className="card-description">{item.Description}</div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </li>
                    <li className="roadmap-category">
                      <div>
                        <div className="uk-grid-medium uk-child-width-1-3@m uk-grid-match" data-uk-grid>
                          {data.strapiRoadmap.InProgress.map((item, i) => {
                            return (
                              <div key={item.id}>
                                <div className={item.Priority + "-priority roadmap-card uk-card uk-card-default uk-card-body"}>
                                  <div className="card-title">{item.Title}</div>
                                    <div className="card-description">{item.Description}</div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </li>
                    <li className="roadmap-category">
                      <div>
                        <div className="uk-grid-medium uk-child-width-1-3@m uk-grid-match" data-uk-grid>
                          {data.strapiRoadmap.Launched.map((item, i) => {
                            return (
                              <div key={item.id}>
                                <div className={item.Priority + "-priority roadmap-card uk-card uk-card-default uk-card-body"}>
                                  <div className="card-title">{item.Title}</div>
                                    <div className="card-description">{item.Description}</div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </li>
                    <li className="roadmap-category">
                      <div>
                        <div className="uk-grid-medium uk-child-width-1-3@m uk-grid-match" data-uk-grid>
                          {data.strapiRoadmap.InConsideration.map((item, i) => {
                            return (
                              <div key={item.id}>
                                <div className={item.Priority + "-priority roadmap-card uk-card uk-card-default uk-card-body"}>
                                  <div className="card-title">{item.Title}</div>
                                    <div className="card-description">{item.Description}</div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </li>
                </ul>
            
          
        </section>


      </Layout>

    )}
  
  />
)

export default RoadmapPage
